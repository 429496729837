.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aml_sidebar {
  width: 30rem !important;
}

table tr td {
  vertical-align: middle;
}

.sidebar_box {
  padding: 10px;
  background-color: #79827c2e;
  border-radius: 10px;
}

.sidebar_table {
  width: 100%;
  overflow-y: auto;
}

.p-sidebar,
.p-sidebar-mask.p-component-overlay {
  font-family: "Cairo" !important;
}

.table-responsive .table.table-hover thead tr th {
  min-width: 150px;
  padding: auto 5px;
}

.navbar-toggler {
  background-color: #fff;
}

body.ltr .table-header .btn,
body.ltr .table-header .search-input,
body.ltr .table-footer .results-pagination .pagination .prev,
body.ltr .table-footer .results-pagination .pagination .next,
body.ltr .box_container,
.submit-button {
  border-radius: 8.5px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

body.rtl .table-header .btn,
body.rtl .table-header .search-input,
body.rtl .table-footer .results-pagination .pagination .prev,
body.rtl .table-footer .results-pagination .pagination .next,
body.rtl .box_container,
.submit-button {
  border-radius: 8.5px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.payout_actions button.btn {
  min-width: 130px !important;
  /* background-color: gray !important;   */
  border-radius: 50px !important;
}

.position-sticky-table {
  position: sticky;
  top: 0;
  left: 0;
}

.box_container .table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 15px;
}

.box_container .table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(46, 52, 88, 0.8);
  border-radius: 6px;
}

.box_container .table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: rgba(46, 52, 88, 1);
}

.input-group.search-input {
  margin: 0 auto !important;
  padding: 3px 0 !important;
  /* margin-right: 10px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.aml.dropdown-menu {
  background-image: none !important;
  background-color: #fff !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Collapsible span {
  padding: 0 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
