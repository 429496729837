.box_container {
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.bg-gradient-dark {
    background-image: linear-gradient(310deg,#141727,#3a416f) !important;
    color: #fff !important;
    font-size: 0.8rem;
    font-weight: 700;
    padding-inline-end: 2rem;
    &:hover {
        background-color: #344767;
        border-color: #344767;
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
        transform: scale(1.02);
    }
}

.btn {
    margin-bottom: 1rem;
    letter-spacing: -.025rem;
    background-size: 150%;
    background-position-x: 25%;
    border: 0;
    transition: all .15s ease-in;
    &:hover {
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
        transform: scale(1.02);
    }
}

.btn-outline-danger {
    --bs-btn-color: #ea0606;
    --bs-btn-border-color: #ea0606 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ea0606;
    --bs-btn-hover-border-color: #ea0606;
    border: 1px solid;
}

.btn-rounded {
    border-radius: 50% !important;
    margin: 2px;
}

@media (min-width: 991.98px) { 
    .custom-modal.show-modal .modal-content {
      &.small {
        width: 30% !important;
        min-width: 30rem !important;
      }
    }
  }

.bg-gradient-primary {
    background-image: linear-gradient(112deg, #5f5b70, #f5b336);
}

.border-radius-lg {
    border-radius: 0.75rem;
}

.icon-lg {
    width: 64px;
    height: 64px;
    i {
        top: 31%;
        font-size: 1.25rem;
    }
}

.text-center {
    text-align: center !important;
}

.icon {
    fill: currentColor;
    stroke: none;
}

.icon-shape i {
    color: #fff;
    opacity: 1;
    position: relative;
}

.btn-back {
    width: 42px;
    height: 42px;
    border: 1px solid #dee2e6;
    color: #9d9d9d;
}

.rotate-275 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    &:hover {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

.search-input {
    box-shadow: 0 -1px 10px -4px rgb(0 0 0 / 7%), 0 1px 7px -2px rgb(0 0 0 / 6%);
}
.dropdown-menu {
    background-image: linear-gradient(310deg,#141727,#3a416f)!important;
    color: #fff !important;
    border: unset !important;
    .dropdown-item {
        &:hover {
            background-color: rgba(233,236,239,.412)!important;
            cursor: pointer;
        }
        font-size: 14px !important;
        color: WHITE;
    }
    li {
        border-bottom: 1px solid #e8e7e712 !important;
    }
}

.table-header .btn {
    padding: 13px 14px !important;
    margin: 0;
    height: 100%;
}
